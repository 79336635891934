// page
.ss-page {
  min-height: 100vh;
  &__header {
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: flex-start;
    }
    display: flex;
    align-items: center;
    align-self: center;
    padding: 0.1rem;
  }
  
  &__title {
    @include media-breakpoint-down(sm) {
      margin-bottom: 0;
    }
    padding-right: 0.5rem;
    margin-bottom: 0.5rem;
  }
  
  &__breadcrumb {
    @include media-breakpoint-down(sm) {
      align-self: flex-start;
    }
    height: 2.5rem;
    padding-top: 0.5rem;    
    text-transform: capitalize;
    
    background-color: #e9ecef;
    
    background: theme-color('light');
   }
  
  .row {
    // stylelint-disable-next-line      
    .col,
    > [class^='col-'] {
      margin-bottom: 1.5rem;
    }
  }

  .breadcrumb {
    padding: 0.75rem   1rem;
    margin-bottom: 2rem;
    background-color: #e9ecef;
  }
}
  
.ss-page-spinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
